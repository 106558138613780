<template>
    <v-container fluid>
        <v-row no-gutters class="pt-2">
            <v-col cols="12">
                <v-form class="custom-form" ref="saleForm" @submit.prevent="saveSale">
                    <v-row dense>
                        <v-col cols="12" md="12">
                            <v-row dense>
                                <v-col cols="12">
                                    <v-card height="100%" :elevation="1" color="white lighten-4">
                                        <v-toolbar color="white lighten-4" :elevation="0" height="40px">
                                            <v-toolbar-title class="subtitle-2">Customer Information</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text class="py-1 pa-0 pl-2 pr-2">
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-row>
                                                        <v-col cols="3" class="py-1 caption">Cust. Type</v-col>
                                                        <v-col cols="9" class="py-0 pa-0 pl-3 pr-4">
                                                            <v-radio-group v-model="type" row>
                                                                <v-radio label="Retail" value="Retail"></v-radio>
                                                                <v-radio label="Wholesale" value="Wholesale"></v-radio>
                                                            </v-radio-group>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-row>
                                                        <v-col cols="3" class="py-1 caption">Customer</v-col>
                                                        <v-col cols="8" class="py-0 pa-0 pl-3 pr-4">
                                                            <v-combobox dense outlined hide-details @focus="$store.dispatch('customer/getCustomers', { type: type })" :items="customers" :loading="$store.getters['customer/loadingCustomers']" item-text="display_text" item-value="id" v-model="customer">
                                                            </v-combobox>
                                                        </v-col>
                                                        <v-col cols="1" class="py-0 pl-2 pa-0" style="margin-left: -17px;">
                                                            <v-btn to="/customer" target="_blank" fab x-small depressed class="text_bg_fave">
                                                                <v-icon color="white">mdi-plus</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>

                                            <v-row v-if="customer.type == 'G'" class="py-2">
                                                <v-col cols="3" class="py-1 caption">Name</v-col>
                                                <v-col cols="9" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model="customer.name"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="3" class="py-1 caption">Phone</v-col>
                                                <v-col cols="9" class="py-0">
                                                    <v-text-field dense outlined hide-details v-model="customer.phone" :readonly="customer.type == 'G' ? false : true"></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="3" class="py-1 caption">Address</v-col>
                                                <v-col cols="9" class="py-0">
                                                    <v-textarea dense outlined hide-details height="8vh" v-model="customer.address" :readonly="customer.type == 'G' ? false : true"></v-textarea>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>

                            </v-row>

                            <v-row>
                                <v-col cols="12">
                                    <v-card class="px-0 shadow1">
                                        <v-toolbar color="white lighten-4" :elevation="0" height="40px">
                                            <v-toolbar-title class="subtitle-1" style="width: 100%;">
                                                <div style="display: flex; justify-content: space-between;width: 100%;">
                                                    Cart Information
                                                    <v-btn @click="addToCart" class="text_bg_fave" style="margin-left: auto;">
                                                        Add Product <v-icon color="white">mdi-plus</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text class="py-3 px-3">
                                            <v-simple-table dense>
                                                <template v-slot:default>
                                                    <thead>
                                                        <tr>
                                                            <th>SL</th>
                                                            <th>Product Name</th>
                                                            <th>Purchase Rate</th>
                                                            <th>Sale Rate</th>
                                                            <th>Quantity</th>
                                                            <th>Total</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, ind) in cart" :key="ind">
                                                            <td class="text-center" style="width:5%">{{ 
                                                                 }}</td>
                                                            <td>
                                                                <input type="text" class="form_control" v-model="item.name" v-fSelect placeholder="Service name">
                                                            </td>
                                                            <td class="text-right" style="width:12%">
                                                                <input type="number" class="form_control" v-model.number="item.purchase_rate" v-fSelect placeholder="Purchase rate">
                                                            </td>
                                                            <td class="text-right" style="width:12%">
                                                                <input type="number" class="form_control" v-model.number="item.sale_rate" v-fSelect placeholder="Sale rate">
                                                            </td>
                                                            <td class="text-right" style="width:10%">
                                                                <input type="number" class="form_control" v-model.number="item.quantity" v-fSelect placeholder="Quantity">
                                                            </td>
                                                            <td class="text-right" style="width:10%">{{ item.total }}</td>
                                                            <td class="text-center" style="width:10%">
                                                                <v-icon small @click="deleteCart(ind)" color="error">mdi-delete-circle-outline</v-icon>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12">
                            <v-row dense>
                                <v-col cols="12">
                                    <v-card color="white lighten-4 shadow1">
                                        <v-toolbar color="white lighten-4" :elevation="0" height="40px">
                                            <v-toolbar-title class="subtitle-2">Invoice Information</v-toolbar-title>
                                        </v-toolbar>
                                        <v-card-text class="py-1 pa-0 pl-2 pr-2">
                                            <v-row>
                                                <v-col col="6">
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Invoice</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field dense outlined hide-details v-model="sale.invoice" readonly></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Date</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field dense outlined hide-details v-model="sale.date"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Employee</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-combobox dense outlined hide-details @focus="$store.dispatch('employee/getEmployees')" :items="$store.getters['employee/employees']" :loading="$store.getters['employee/loadingEmployees']" item-text="display_text" item-value="id" v-model="employee">
                                                            </v-combobox>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Machine Name</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field dense outlined hide-details v-model="sale.po_no"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">PO Date</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field dense outlined hide-details v-model="sale.po_date"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col col="6">
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Sub Total</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field dense outlined hide-details type="number" min="0" readonly v-model="sale.sub_total"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Vat Tax</v-col>
                                                        <v-col cols="3" class="py-0">
                                                            <v-text-field dense outlined hide-details type="number" min="0" v-model.number="vatPercent" @input="calculateTotal" id="vat"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="1"><span class="ml-1">%</span></v-col>
                                                        <v-col cols="4" class="py-0">
                                                            <v-text-field dense outlined hide-details type="number" min="0" v-model.number="sale.vat" @input="calculateTotal"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Discount</v-col>
                                                        <v-col cols="3" class="py-0">
                                                            <v-text-field dense outlined hide-details type="number" min="0" v-model.number="disPercent" @input="calculateTotal" id="discount"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="1" class="py-0"><span class="ml-1">%</span></v-col>
                                                        <v-col cols="4" class="py-0">
                                                            <v-text-field dense outlined hide-details type="number" min="0" v-model.number="sale.discount" @input="calculateTotal"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <!-- <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Transport</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field dense outlined hide-details type="number" min="0" v-model.number="sale.transport" @input="calculateTotal"></v-text-field>
                                                        </v-col>
                                                    </v-row> -->
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Total</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field dense outlined hide-details type="number" min="0" v-model.number="sale.total" @input="calculateTotal" readonly></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Paid</v-col>
                                                        <v-col cols="8" class="py-0">
                                                            <v-text-field dense outlined hide-details type="number" min="0" v-model.number="sale.paid" @input="calculateTotal" :readonly="customer.type == 'G' ? true : false"></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters>
                                                        <v-col cols="4" class="py-1 caption">Due</v-col>
                                                        <v-col cols="4" class="py-0">
                                                            <v-text-field dense outlined hide-details readonly v-model.number="sale.due" @input="calculateTotal"></v-text-field>
                                                        </v-col>
                                                        <v-col cols="4" class="pl-2">
                                                            <v-text-field dense outlined hide-details readonly></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>


                                            <v-row no-gutters>
                                                <v-col cols="4" class="py-1"></v-col>
                                                <v-col cols="4" class="py-1">
                                                    <v-btn type="submit" height="30px" dark block class="text_bg_fave" :loading="loadingForSave">Save</v-btn>
                                                </v-col>
                                                <v-col cols="4" class="pl-2 py-1">
                                                    <v-btn type="reset" @click="resetForm" height="30px" dark block color="deep-orange">Clear</v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import swal from 'sweetalert'

export default {
    name: 'Repair',
    data: () => ({
        type: 'Retail',
        sale: {
            id: null,
            invoice: '',
            date: new Date().toISOString().substr(0, 10),
            customer_id: null,
            employee_id: null,
            po_no: '',
            po_date: new Date().toISOString().substr(0, 10),
            sub_total: 0,
            vat: 0,
            discount: 0,
            transport: 0,
            total: 0,
            paid: 0,
            due: 0,
        },
        vatPercent: 0.0,
        disPercent: 0.0,
        customer: {
            id: null,
            name: '',
            phone: '',
            address: '',
            type: '',
            display_text: 'Select Customer'
        },
        employee: null,
        cart: [],
        editCartIndex: null,
        modal: false,
        loadingForSave: false,
        stock: ''
    }),

    watch: {
        customer(customer) {
            if (customer == undefined) return;
            this.sale.customer_id = customer.id;
            this.customer = customer;
            this.calculateTotal()
        },
        employee(employee) {
            if (employee == undefined) return;
            this.sale.employee_id = employee.id;
        },
        cart: {
            handler(crt) {
                crt.forEach(item => {
                    if (item.sale_rate && item.quantity) {
                        item.total = item.sale_rate * item.quantity
                    }
                })
                this.calculateTotal()
            },
            deep: true
        },
    },

    computed: {
        customers() {
            let customer = this.$store.getters['customer/customers'];
            customer.unshift({
                id: null,
                display_text: "General Customer",
                type: 'G',
                name: '',
                phone: '',
                address: ''
            })
            return customer;
        },
    },

    async created() {
        this.sale.invoice = await this.$store.dispatch('repair/generateRepairCode');
        if (this.$route.params.id != undefined) {
            await this.$store.dispatch('repair/getRepairs', { id: this.$route.params.id });
            await this.getSales();
        } else {
            this.addToCart()
        }
    },

    methods: {
        addToCart() {
            let product = {
                name: null,
                purchase_rate: 0,
                sale_rate: 0,
                quantity: 1,
                total: 0,
            }

            this.cart.unshift(product);
        },

        editCart(index) {
            let cartProduct = this.cart[index];
            this.product = cartProduct;
            this.editCartIndex = index;
        },

        deleteCart(ind) {
            this.cart.splice(ind, 1);
        },

        calculateTotal() {
            this.sale.sub_total = this.cart.reduce((p, c) => { return +p + +c.total }, 0).toFixed(2);

            if (event.target.id == "vat") {
                this.sale.vat = ((+this.sale.sub_total * +this.vatPercent) / 100).toFixed(2);
            } else {
                this.vatPercent = ((+this.sale.vat / +this.sale.sub_total) * 100).toFixed(2);
            }

            if (event.target.id == "discount") {
                this.sale.discount = ((+this.sale.sub_total * +this.disPercent) / 100).toFixed(2);
            } else {
                this.disPercent = ((+this.sale.discount / +this.sale.sub_total) * 100).toFixed(2);
            }

            this.sale.total = ((+this.sale.sub_total + +this.sale.vat + +this.sale.transport) - +this.sale.discount).toFixed(2);

            if (this.customer.type == 'G') {
                this.sale.paid = this.sale.total;
            } else {
                this.sale.due = (+this.sale.total - +this.sale.paid).toFixed(2);
            }

        },

        clearProduct() {
            this.editCartIndex = null;
            this.stock = '';
        },

        validateSale() {
            let isValid = true;

            this.$refs.saleForm.validate();

            this.$refs.saleForm.inputs.forEach(input => {
                if (input.hasError) isValid = false;
            })

            if (!isValid) return;

            if (this.customer.type == '' && this.sale.customer_id == null) {
                this.$store.dispatch('snackbar/error', 'Select a customer');
                isValid = false;
                return isValid;
            }

            if (this.cart.length == 0) {
                this.$store.dispatch('snackbar/error', 'Cart is empty');
                isValid = false;
                return isValid;
            }

            if (this.sale.employee_id == null) {
                this.$store.dispatch('snackbar/error', 'Select an employee');
                isValid = false;
                return isValid;
            }

            return isValid;
        },

        async saveSale() {
            if (!this.validateSale()) {
                return;
            }
            // return;
            let cart = this.cart.filter(item => {
                if (item.name && item.quantity) {
                    return true;
                }
                return false;
            })
            this.cart.map(item => {
                if (!item.name) {
                    this.$store.dispatch('snackbar/error', 'Product name field cannot be empty');
                    return;
                }
                if (!item.sale_rate) item.sale_rate = 0;
                if (!item.purchase_rate) item.purchase_rate = 0;
                return item;
                // item.purchase_rate && item.sale_rate
            })
            if (cart.length == 0) {
                this.$store.dispatch('snackbar/error', 'Required field cannot be empty');
                return;
            }
            let data = {
                repair: this.sale,
                cart: cart,
                customer: this.customer.type == 'G' ? this.customer : null
            }

            this.loadingForSave = true;

            let res = await this.$store.dispatch('repair/saveRepair', data);

            if (res) {
                this.resetForm();
                if (res.isSuccess) {
                    this.resetForm();
                    swal({
                        title: 'Repair success! Do you want to show invoice ?',
                        icon: 'success',
                        buttons: true
                    }).then(response => {
                        if (response) this.$router.push(`/repair-invoice/${res.id}`)
                    })
                }
            }

            this.loadingForSave = false
        },

        async resetForm() {
            this.cart = [];
            Object.keys(this.sale).map(k => this.sale[k] = '');
            this.$refs.saleForm.resetValidation();
            this.sale.id = null;
            this.customer = null;
            this.sale.date = new Date().toISOString().substr(0, 10);
            this.sale.po_date = new Date().toISOString().substr(0, 10);
            this.sale.invoice = await this.$store.dispatch('repair/generateRepairCode');
        },

        async getSales() {
            let sale = await this.$store.getters['repair/repairs'][0]
            this.sale.id = sale.id;
            this.sale.invoice = sale.invoice;
            this.sale.date = sale.date;
            this.sale.expire_date = sale.expire_date;
            this.sale.customer_id = sale.customer_id;
            this.sale.po_no = sale.po_no;
            this.sale.po_date = sale.po_date;
            this.sale.po_date = sale.po_date;
            this.sale.sub_total = sale.sub_total;
            this.sale.vat = sale.vat;
            this.sale.discount = sale.discount;
            this.sale.transport = sale.transport;
            this.sale.total = sale.total;
            this.sale.paid = sale.paid;
            this.sale.due = sale.due;

            this.customer = sale?.customer;
            this.type = sale?.customer?.type;
            this.customer.display_text = `${sale?.customer.code} - ${sale?.customer.name == null ? '' : sale?.customer.name} - ${sale?.customer.phone == null ? '' : sale?.customer.phone}`;

            this.employee = sale?.employee;
            this.employee.display_text = `${sale?.employee.emp_id} - ${sale?.employee.name}`;

            sale?.repair_details.forEach(item => {
                this.cart.unshift({
                    id: item.id,
                    name: item.service_name,
                    purchase_rate: item.purchase_rate,
                    sale_rate: item.sale_rate,
                    quantity: item.quantity,
                    total: item.total
                })
            });
        }
    }

}
</script>

<style lang="scss" scoped>
.product-name {
    font-size: 12px;
    line-height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.form_control {
    border: 1px solid #ccc;
    padding: 3px 5px;
    width: 100%;
}

.form_control:focus {
    outline: none;
}

.v-data-table--dense>.v-data-table__wrapper>table>tbody>tr>td {
    // height: 18px !important;
    border: 1px solid #ccc;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #000 !important;
    font-weight: 600 !important;
    border: 1px solid #ccc !important;
    text-align: center;
    // height: 25px !important;
    letter-spacing: 0.7px;
}

.caption {
    font-weight: 500 !important;
    color: #000 !important;
}

.container {
    padding: 2px 12px !important;
}

.stock {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin-top: 25px;

    span {
        font-size: 24px;
        font-weight: normal;
    }
}

.v-input--selection-controls {
    margin-top: 0px !important;
    // height: 34px !important;
}
</style>
